var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [
        _vm._v("Additional " + _vm._s(_vm.type) + " information")
      ]),
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "one-half" } },
            [
              _c("gov-body", [
                _vm._v(
                  "\n        This section allows you to add more information to your\n        " +
                    _vm._s(_vm.type) +
                    " page. The more detail that can be provided, the better.\n      "
                )
              ]),
              _c("gov-section-break", { attrs: { size: "l" } }),
              _c(
                "ck-select-input",
                {
                  attrs: {
                    value: _vm.wait_time,
                    id: "wait_time",
                    label: "Usual wait time (if applicable)",
                    options: _vm.waitTimeOptions,
                    error: _vm.errors.get("wait_time")
                  },
                  on: {
                    input: function($event) {
                      _vm.$emit("update:wait_time", $event)
                      _vm.$emit("clear", "wait_time")
                    }
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "hint" },
                    [
                      _c("gov-hint", { attrs: { for: "wait_time" } }, [
                        _vm._v(
                          "\n            Provide an indication of how long someone may have to wait to\n            access your " +
                            _vm._s(_vm.type) +
                            " after initial contact has been made. You\n            can amend this in future as and when circumstances change.\n          "
                        )
                      ]),
                      _c("gov-hint", { attrs: { for: "wait_time" } }, [
                        _vm._v(
                          "\n            If this is not relevant to your " +
                            _vm._s(_vm.type) +
                            ", please skip this\n            question.\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                2
              ),
              _c(
                "ck-radio-input",
                {
                  attrs: {
                    value: _vm.is_free,
                    id: "is_free",
                    label: "Is the " + _vm.type + " free?",
                    options: _vm.isFreeOptions,
                    error: _vm.errors.get("is_free")
                  },
                  on: {
                    input: function($event) {
                      _vm.$emit("update:is_free", $event)
                      _vm.$emit("clear", "is_free")
                    }
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "hint" },
                    [
                      _c("gov-hint", { attrs: { for: "is_free" } }, [
                        _vm._v(
                          "\n            Indicates whether your " +
                            _vm._s(_vm.type) +
                            " is completely free, or if some\n            elements of the service must be paid for. Users can filter their\n            searches based on the answer you provide.\n          "
                        )
                      ]),
                      _c("gov-hint", { attrs: { for: "is_free" } }, [
                        _vm._v(
                          "\n            If the cost of your " +
                            _vm._s(_vm.type) +
                            " varies, you may want to split these\n            into different " +
                            _vm._s(_vm.type) +
                            " listings.\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                2
              ),
              _vm.is_free === false
                ? _c(
                    "gov-inset-text",
                    [
                      _c("ck-text-input", {
                        attrs: {
                          value: _vm.fees_text,
                          id: "fees_text",
                          label: "How much does it cost? (if applicable)",
                          hint:
                            "Please indicate the basic cost of the " +
                            _vm.type +
                            '. If there are multiple price points, please provide an indicative range (eg. "5-10 per session").',
                          type: "text",
                          error: _vm.errors.get("fees_text"),
                          maxlength: 75
                        },
                        on: {
                          input: function($event) {
                            _vm.$emit("update:fees_text", $event)
                            _vm.$emit("clear", "fees_text")
                          }
                        }
                      }),
                      _c("ck-text-input", {
                        attrs: {
                          value: _vm.fees_url,
                          id: "fees_url",
                          label:
                            "Please provide a link to full pricing table (if applicable)",
                          type: "url",
                          error: _vm.errors.get("fees_url")
                        },
                        on: {
                          input: function($event) {
                            _vm.$emit("update:fees_url", $event)
                            _vm.$emit("clear", "fees_url")
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.appCqcLocationActive
                ? _c(
                    "ck-text-input",
                    {
                      attrs: {
                        value: _vm.cqc_location_id,
                        id: "cqc_location_id",
                        label: "CQC Location ID number",
                        type: "text",
                        error: _vm.errors.get("cqc_location_id")
                      },
                      on: {
                        input: function($event) {
                          _vm.$emit("update:cqc_location_id", $event)
                          _vm.$emit("clear", "cqc_location_id")
                        }
                      }
                    },
                    [
                      _c(
                        "template",
                        { slot: "hint" },
                        [
                          _c(
                            "gov-hint",
                            { attrs: { for: "cqc_location_id" } },
                            [
                              _vm._v(
                                "\n            Please provide the service's Care Quality Commission Location ID\n            number if it has one. This will be used to display information\n            about the CQC rating on the service page.\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "ck-textarea-input",
                {
                  attrs: {
                    value: _vm.testimonial,
                    id: "testimonial",
                    label:
                      "Quote from a satisfied user, client, beneficiary or customer",
                    maxlength: 150,
                    error: _vm.errors.get("testimonial")
                  },
                  on: {
                    input: function($event) {
                      _vm.$emit("update:testimonial", $event)
                      _vm.$emit("clear", "testimonial")
                    }
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "hint" },
                    [
                      _c("gov-hint", { attrs: { for: "testimonial" } }, [
                        _vm._v(
                          "\n            Please enter a quote from a service user highlighting a positive\n            outcome to help promote your good work. For example:\n          "
                        )
                      ]),
                      _c("gov-hint", { attrs: { for: "testimonial" } }, [
                        _vm._v(
                          "\n            This " +
                            _vm._s(_vm.type) +
                            " changed my life!\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                2
              ),
              _c(
                "ck-text-input",
                {
                  attrs: {
                    value: _vm.video_embed,
                    id: "video_embed",
                    label: _vm.$options.filters.ucfirst(_vm.type) + " video",
                    type: "url",
                    error: _vm.errors.get("video_embed")
                  },
                  on: {
                    input: function($event) {
                      _vm.$emit("update:video_embed", $event)
                      _vm.$emit("clear", "video_embed")
                    }
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "hint" },
                    [
                      _c("gov-hint", { attrs: { for: "video_embed" } }, [
                        _vm._v(
                          "\n            If you have a short video (less than 5 minutes) showcasing your\n            " +
                            _vm._s(_vm.type) +
                            ", please add a link below to the site that hosts it.\n          "
                        )
                      ]),
                      _c("gov-hint", { attrs: { for: "video_embed" } }, [
                        _vm._v(
                          "\n            Youtube and Vimeo links are accepted.\n          "
                        )
                      ]),
                      _c(
                        "gov-hint",
                        { attrs: { for: "video_embed" } },
                        [
                          _c(
                            "gov-link",
                            { attrs: { href: _vm.videoEmbedHelpHref } },
                            [
                              _vm._v(
                                "\n              Need help editing or creating a " +
                                  _vm._s(_vm.type) +
                                  " video?\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c("gov-section-break", { attrs: { size: "m", visible: "" } }),
      _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("Contact details")]),
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "one-half" } },
            [
              _c("gov-body", [
                _vm._v(
                  "\n        Please provide your " +
                    _vm._s(_vm.type) +
                    "’s public-facing contact details. These\n        will be displayed on your " +
                    _vm._s(_vm.type) +
                    "’s page on the " +
                    _vm._s(_vm.appName) +
                    "\n        website.\n      "
                )
              ]),
              _c("gov-section-break", { attrs: { size: "l" } }),
              _c("ck-text-input", {
                attrs: {
                  value: _vm.contact_name,
                  id: "contact_name",
                  label: "Contact name",
                  hint:
                    "Provide the contact name (First name & Surname) for this " +
                    _vm.type +
                    ", or a generic entry if this isn’t applicable e.g. ‘Enquiries’, or ‘Helpdesk’.",
                  type: "text",
                  error: _vm.errors.get("contact_name")
                },
                on: {
                  input: function($event) {
                    _vm.$emit("update:contact_name", $event)
                    _vm.$emit("clear", "contact_name")
                  }
                }
              }),
              _c(
                "ck-text-input",
                {
                  attrs: {
                    value: _vm.contact_phone,
                    id: "contact_phone",
                    label: "Contact phone number",
                    type: "tel",
                    error: _vm.errors.get("contact_phone")
                  },
                  on: {
                    input: function($event) {
                      _vm.$emit("update:contact_phone", $event)
                      _vm.$emit("clear", "contact_phone")
                    }
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "hint" },
                    [
                      _c("gov-hint", { attrs: { for: "contact_phone" } }, [
                        _vm._v(
                          "\n            Please provide a public facing phone number for people to contact\n            you on.\n          "
                        )
                      ]),
                      _c("gov-hint", { attrs: { for: "contact_phone" } }, [
                        _vm._v(
                          "\n            Please use the following formatting:\n            "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            0208XXXXXXX for landline or 07XXXXXXXXX for mobile.\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                2
              ),
              _c("ck-text-input", {
                attrs: {
                  value: _vm.contact_email,
                  id: "contact_email",
                  label: "Public " + _vm.type + " email address",
                  hint:
                    "Please provide the contact email address for the " +
                    _vm.type +
                    ".",
                  type: "email",
                  error: _vm.errors.get("contact_email")
                },
                on: {
                  input: function($event) {
                    _vm.$emit("update:contact_email", $event)
                    _vm.$emit("clear", "contact_email")
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }