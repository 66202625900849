var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [
        _vm._v("What does your " + _vm._s(_vm.type) + " offer")
      ]),
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "one-half" } },
            [
              _c("gov-body", [
                _vm._v(
                  "\n        These sections should describe what your " +
                    _vm._s(_vm.type) +
                    " offers and is the\n        main body of content on your page on " +
                    _vm._s(_vm.appName) +
                    ".\n      "
                )
              ]),
              _c("gov-section-break", { attrs: { size: "l" } }),
              _c("ck-textarea-input", {
                attrs: {
                  value: _vm.intro,
                  id: "intro",
                  label: "Your " + _vm.type + ", an overview?",
                  hint:
                    "Write a brief description of what your " +
                    _vm.type +
                    " does.",
                  maxlength: 300,
                  error: _vm.errors.get("intro")
                },
                on: {
                  input: function($event) {
                    _vm.$emit("update:intro", $event)
                    _vm.$emit("clear", "intro")
                  }
                }
              }),
              _c("gov-heading", { attrs: { size: "m" } }, [
                _vm._v("What you offer")
              ]),
              _c("gov-body", [
                _vm._v(
                  "\n        Include a bullet list of some of the things you do as a " +
                    _vm._s(_vm.type) +
                    ".\n      "
                )
              ]),
              _c("gov-body", [
                _vm._v(
                  "\n        For example: (Weekly Meetups, Peer Support, Group Therapy)\n      "
                )
              ]),
              _c("ck-offerings-input", {
                attrs: { offerings: _vm.offerings, errors: _vm.errors },
                on: {
                  input: function($event) {
                    return _vm.$emit("update:offerings", $event)
                  },
                  clear: function($event) {
                    return _vm.$emit("clear", $event)
                  }
                }
              }),
              _c("ck-wysiwyg-input", {
                attrs: {
                  value: _vm.description,
                  id: "description",
                  label: "Long description",
                  hint:
                    "This is the largest body of text on your page. Fill it with everything else someone should know about your " +
                    _vm.type +
                    ". Use headers, bullets and formatting for the maximum effect.",
                  error: _vm.errors.get("description"),
                  large: "",
                  maxlength: 10000
                },
                on: {
                  input: function($event) {
                    _vm.$emit("update:description", $event)
                    _vm.$emit("clear", "description")
                  }
                }
              }),
              _vm._t("default")
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }