var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.galleryItems, function(galleryItem, index) {
        return _c(
          "gov-inset-text",
          { key: galleryItem.$index },
          [
            _c("ck-image-input", {
              attrs: {
                id: "Ck::GalleryItem::" + galleryItem.$index,
                label: "Upload an item to the gallery",
                "existing-url": galleryItem.hasOwnProperty("url")
                  ? galleryItem.url
                  : undefined
              },
              on: {
                input: function($event) {
                  return _vm.onGalleryItemInput($event, index)
                }
              }
            }),
            _vm.errors.has("gallery_items." + index) ||
            _vm.errors.has("gallery_items." + index + ".file_id")
              ? _c("gov-error-message", {
                  attrs: { for: galleryItem.$index },
                  domProps: {
                    textContent: _vm._s(
                      _vm.errors.get([
                        "gallery_items." + index,
                        "gallery_items." + index + ".file_id"
                      ])
                    )
                  }
                })
              : _vm._e(),
            _c(
              "gov-button",
              {
                attrs: { error: "" },
                on: {
                  click: function($event) {
                    return _vm.onDeleteGalleryItem(index)
                  }
                }
              },
              [_vm._v("Delete")]
            )
          ],
          1
        )
      }),
      _c(
        "div",
        [
          _c(
            "gov-button",
            { on: { click: _vm.onAddGalleryItem } },
            [
              _vm.galleryItems.length === 0
                ? [_vm._v("Add gallery item")]
                : [_vm._v("Add another")]
            ],
            2
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }